<p-confirmDialog #cd key="confirmationMessageModal">
  <ng-template pTemplate="headless" let-context>
    <div
      class="flex flex-column align-items-center p-4 surface-overlay border-round relative w-27rem"
    >
      <div class="absolute" style="top: 15px; right: 15px">
        <p-button
          icon="pi pi-times text-lg"
          [rounded]="true"
          [text]="true"
          severity="secondary"
          styleClass="p-1 w-2rem h-2rem text-lg text-gray-400"
          (click)="cd.reject()"
        />
      </div>

      <div class="mt-3">
        <i [class]="context.icon" class="text-6xl"></i>
      </div>

      <div class="font-medium text-xl mb-2 mt-4 mx-4 text-center line-height-3">
        {{ context.header }}
      </div>

      <div class="mx-6 text-center line-height-3">{{ context.message }}</div>

      <form [formGroup]="messageForm" class="mt-4" style="width: 100%">
        <rpp-form-input-wrapper
          [label]="context.formLabel"
          [required]="true"
          [hasValidation]="false"
        >
          <input
            type="text"
            [formControl]="messageForm.controls.message"
            pInputText
            [placeholder]="context.formPlaceholder"
            id="name"
            maxlength="75"
          />
        </rpp-form-input-wrapper>
      </form>

      <div
        class="flex flex-column align-items-center justify-content-center gap-2 mt-4 w-full"
      >
        <p-button
          *ngIf="context.acceptVisible"
          [label]="context.acceptLabel"
          class="w-full"
          styleClass="w-full justify-content-center"
          [autofocus]="true"
          pAutoFocus
          [disabled]="messageForm.controls.message.value === ''"
          (click)="cd.accept()"
        />
        <p-button
          *ngIf="context.rejectVisible"
          [label]="context.rejectLabel"
          class="w-full"
          styleClass="w-full justify-content-center"
          [outlined]="true"
          (click)="cd.reject()"
        />
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
