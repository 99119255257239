import { RppFormInputWrapperComponent } from '@/presentation/molecules/form-input-wrapper/form-input-wrapper.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, viewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AutoFocusModule } from 'primeng/autofocus';
import { ConfirmDialog, ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'rpp-modal-confirmation-message-form',
  standalone: true,
  imports: [
    CommonModule,
    ConfirmDialogModule,
    AutoFocusModule,
    RppFormInputWrapperComponent,
    ReactiveFormsModule,
    InputTextModule,
  ],
  templateUrl: './modal-confirmation-message-form.component.html',
  styleUrl: './modal-confirmation-message-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RppModalConfirmationMessageFormComponent {
  cd = viewChild.required<ConfirmDialog>('cd');

  messageForm = this.fb.group({
    message: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(75),
    ]),
  });

  get messageValue() {
    return this.messageForm.controls.message.value;
  }

  constructor(private fb: FormBuilder) {}
}
